<template>
    <el-dialog
        width="600px"
        top="40px"
        title="Lägg till kommentar"
        :lock-scroll="false"
        :visible.sync="visible"
        :before-close="closeModal"
        :close-on-click-modal="false"
        @closed="resetModalState"
        @keydown.enter.native.prevent="handleEnterKey"
    >
        <el-form ref="form" :model="formData" class="px-16">
            <el-form-item label="Kommentar" prop="comment">
                <el-input v-model="formData.comment" />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
            <el-button type="primary" @click="handleFormSubmit" :loading="$waiting.is('creating')">Lägg till</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Api from "../factoring.api.js";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        switchStateIdentifier: Boolean,
        factoringDecisionId: Number,
        decisionTypeIdentifier: Number,
        currentSwitchKey: String,
        factoringInvoiceDecisionId: Number,
        draftInvoiceId: String,
        decisionData: Object,
    },

    data() {
        return {
            formData: {
                comment: "",
                decisionType: "",
                userName2: "",
            },
            decisionData2: {},
            commentResponse: {},
        };
    },

    methods: {
        handleEnterKey(event) {
            if (event.key === "Enter") {
                this.handleFormSubmit();
            }
        },
        handleFormSubmit() {
            if (this.draftInvoiceId) {
                this.saveCommentForCustomerInvoicePage();
            } else {
                this.saveCommentDeleteSignee();
            }
        },
        async saveCommentDeleteSignee() {
            if (this.formData.comment !== "") {
                this.$waiting.start("adding");
                this.formData.decisionType = this.decisionTypeIdentifier;
                await this.assignDecisionData();

                try {
                    this.commentResponse = await Api.saveComment({
                        clientId: this.$route.params.clientId,
                        formData: this.formData,
                        factoringDecisionId: this.factoringDecisionId,
                        decisionData: this.decisionData2,
                    });
                    console.log("🚀 ~ saveCommentDeleteSignee ~ this.commentResponse:", this.commentResponse);
                    this.$notify.success({ title: "Kommentar sparad" });
                    this.$emit("lagg-till-click", this.switchStateIdentifier);

                    this.resetModalState();
                } catch (error) {
                    console.error("Error saving comment:", error);
                }
                this.$waiting.end("adding");

                this.formData.userName = this.commentResponse.userName;
                console.log("🚀 ~ saveCommentDeleteSignee ~ this.formData.userName:", this.formData.userName);

                const factoringComments = {
                    factoringDecisionId: this.factoringDecisionId,
                    decisionType: this.formData.decisionType,
                    comment: this.formData.comment,
                    userName: this.formData.userName,
                };

                console.log("🚀 ~ saveCommentDeleteSignee ~ factoringComments:", factoringComments);
                this.$emit("update-displayed-comment", factoringComments);

                this.formData.comment = "";

                this.$emit("lagg-till-click-decisionType", factoringComments);

                try {
                    const response = await Api.deleteSignDecision({
                        clientId: this.$route.params.clientId,
                        factoringDecisionId: this.factoringDecisionId,
                    });
                    console.log("🚀 ~ file: ClientApproved.vue:489 ~ postSignee ~ response:", response);

                    this.$emit("clear-signees");
                } catch (error) {
                    console.log("Error signing decision:", error.message);
                }
            } else {
                alert("Kommentarsfältet är tomt");
            }
        },
        async assignDecisionData() {
            this.decisionData2 = this.decisionData;
        },

        async saveCommentForCustomerInvoicePage() {
            if (this.formData.comment !== "") {
                console.log("saveCommentForCustomerInvoicePage");
                this.$waiting.start("adding");
                this.formData.decisionType = this.decisionTypeIdentifier;

                try {
                    this.commentResponse = await Api.saveCommentInvoicePage({
                        clientId: this.$route.params.clientId,
                        formData: this.formData,
                        draftInvoiceId: this.draftInvoiceId,
                        factoringInvoiceDecisionId: this.factoringInvoiceDecisionId,
                        decisionData: this.decisionData2,
                    });
                    console.log("🚀 ~ saveCommentForCustomerInvoicePage ~ this.commentResponse:", this.commentResponse);
                    this.$notify.success({ title: "Kommentar sparad" });
                    this.$emit("lagg-till-click", this.switchStateIdentifier);

                    this.resetModalState();
                } catch (error) {
                    console.error("Error saving comment:", error);
                }
                this.$waiting.end("adding");

                this.formData.userName = this.commentResponse.data.userName;
                console.log("🚀 ~ saveCommentForCustomerInvoicePage ~ this.formData.userName:", this.formData.userName);

                const factoringComments = { decisionType: this.formData.decisionType, comment: this.formData.comment, userName: this.formData.userName };

                console.log("🚀 ~ saveCommentForCustomerInvoicePage ~ factoringComments:", factoringComments);
                this.$emit("update-displayed-comment", factoringComments);

                this.formData.comment = "";

                this.$emit("lagg-till-click-decisionType", factoringComments);
            } else {
                alert("Kommentarsfältet är tomt");
            }
        },

        resetModalState() {
            this.modalVisible = false;
        },

        closeModal() {
            this.$emit("close", this.switchStateIdentifier);
        },
    },
};
</script>
